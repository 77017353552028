import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/global.scss"
import { graphql } from "gatsby"
import {createMarkup} from "../helpers/helpers"
// import Menulist from "../components/MenuList"

const NewsPage = ({data}) => {
  const {
    pageData: {
      news
    }
  } = data

  console.log(data)

  return (
    <Layout title="Church News">
      <SEO title="Church News" />
      <section>
        <div className="container">
          <div className="columns is-8 is-variable">
            {/* <div className="column is-one-quarter">
              <aside className="menu" style={{position: `sticky`, top: `1em`}}>
                <Menulist label="March">
                  <li><a>Week 1</a></li>
                  <li><a>Week 2</a></li>
                  <li><a>Week 3</a></li>
                  <li><a>Week 4</a></li>
                </Menulist>
                <Menulist label="February">
                  <li><a>Week 1</a></li>
                  <li><a>Week 2</a></li>
                  <li><a>Week 3</a></li>
                  <li><a>Week 4</a></li>
                </Menulist>
                <Menulist label="January">
                  <li><a>Week 1</a></li>
                  <li><a>Week 2</a></li>
                  <li><a>Week 3</a></li>
                  <li><a>Week 4</a></li>
                </Menulist>
              </aside>
            </div> */}
            <div className="column news">
          <h2 className="title" dangerouslySetInnerHTML={createMarkup(`Announcements: Week of ${news[0].date}`)} />
              <div className="post-content clear-fix">
                <div className="post-entry" dangerouslySetInnerHTML={createMarkup(news[0].content)} />
            </div>
          </div>
        </div>
      </div>
    </section>
    <style jsx='jsx'>{`
      .page-content {
        font-size: .3em;
        line-height: 1.4em;
      }
      .page-content p {
        font-family: Georgia, Times New Roman, serif;
        margin-bottom: 1.5em;
      }
      blockquote {
        font-size: 1.1em;
        line-height: 1.5em;
        display: block;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 30px;
        margin-right: 75px;
      }
    `}</style>
  </Layout>
  )
}

export default NewsPage

export const query = graphql`
    query News {
      pageData {
        news {
          date
          content
        }
      }
    }
`